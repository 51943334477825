const namespaced = true

import api from '../../api/user.js';

const state = {
    user : [],
    role: -1, 
    subscriptionType : -1
}
const mutations = {
    MUTATION_SET_USER(state, value) {
        state.user = value;
    },
    SET_ROLE_USER(state, input_data){
        state.role = input_data;
    },
    
    SET_SUBSCRIPTION_TYPE(state, data){
        state.subscriptionType = data;
    }
}
const actions = {     
    getInformation(context) {
        return new Promise((resolve) => { 
            api.getInformation().then(function (res) {
                context.commit('MUTATION_SET_USER', res.data.data);
                resolve(res.data.data);
            }).catch(function (error) {
                console.log(error)
            })
        })        
    },
    // setRoleUser(context, value){
    //     context.commit('SET_ROLE_USER', value);
    // },
    // setLoginStatus(context, value){
    //     context.commit('SET_LOGINSTATUS', value);
    // },
    // setSubscriptionType(context, value){
    //     context.commit('SET_SUBSCRIPTION_TYPE', value);
    // }
}

const getters = {
    GET_STATUS_LOGGED: state => state.configuration.isLoggedIn ? state.configuration.isLoggedIn : false
}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}