<template>    
    <v-app>
        <v-main>
            <router-view></router-view>
        </v-main>             
    </v-app>
</template>

<script>
    import { mapActions, mapState } from 'vuex';

    export default {
        name: 'App',
        components: {},
        data: () => ({}),
        methods: {
            ...mapActions('configuration', ['resetConfiguration']),
            ...mapActions('notifications',  ['clear']),            
            /**
             * Solo en el caso en que quisieramos ocultarlo en una determinada ruta.
             */
            isShow(){
                return (this.$route.fullPath != '/') ? true : false;
            }
        },
        computed: {
            ...mapState('notifications', ['notification']),
            ...mapState('configuration', ['configuration']),
            ...mapState('authentication', ['isLoggedIn']),
        },
        mounted() {
            if(this.isLoggedIn == false){
                this.resetConfiguration();
            }
        },
        beforeMount (){
            this.clear();
        },
        watch : {
            isLoggedIn : function (nvl){
                if(this.$route.fullPath != '/login'){
                    nvl == false ? this.$router.push({'name' : "login"}) : void(0);
                }
            },
        }
    }
</script>
